import { Box, Link, Typography } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import HackAlumniLogo from "../../assets/logos/light-bg-logo.svg";
import InstagramIcon from "@material-ui/icons/Instagram";
import { MAX_SCREEN_WIDTH } from "../../constants/site";
import React from "react";
import { Link as ReactScrollLink } from "react-scroll";
import { makeStyles } from "@material-ui/core/styles";

export const FOOTER_HEIGHT = 80;
export const FOOTER_HEIGHT_DOWN_SM = 280;

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: FOOTER_HEIGHT,
    [theme.breakpoints.down("sm")]: {
      height: FOOTER_HEIGHT_DOWN_SM,
    },
  },
  footerContent: {
    maxWidth: MAX_SCREEN_WIDTH,
    margin: "0 auto",
    marginTop: theme.spacing(1),
  },
  icon: {
    color: theme.palette.common.white,
  },
  baseTextStyle: {
    cursor: "pointer",
    fontWeight: 600,
    color: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 3.5, 3.5),
      fontSize: theme.typography.pxToRem(20),
    },
  },
  beSocialText: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(3.5),
      fontSize: theme.typography.pxToRem(22),
    },
  },
  instagramText: {
    marginLeft: theme.spacing(1),
    textTransform: "none",
    fontWeight: 600,
    color: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      visibility: "hidden",
    },
  },
  logo: {
    maxHeight: 68,
    width: 150,
    marginLeft: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      width: 175,
    },
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      width: "16.375rem",
      height: "4.3125rem",
    },
  },
  supportUs: {
    marginLeft: 0,
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Grid container alignItems="center" className={classes.footerContent}>
        <Grid item xs={12} md={3}>
          <ReactScrollLink
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <img
              src={HackAlumniLogo}
              alt="Hack Alumni Logo"
              className={classes.logo}
            />
          </ReactScrollLink>
        </Grid>
        <Grid item xs={6} md>
          <ReactScrollLink
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <Typography className={classes.baseTextStyle}>Home</Typography>
          </ReactScrollLink>
        </Grid>
        <Grid item xs={6} md lg={4}>
          <ReactScrollLink
            to="supportus"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <Typography
              className={`${classes.baseTextStyle} ${classes.supportUs}`}
            >
              Support Us
            </Typography>
          </ReactScrollLink>
        </Grid>
        <Grid item xs={6} md>
          <Typography className={classes.beSocialText}>Be Social.</Typography>
        </Grid>
        <Grid item xs={6} md>
          <Link
            href="https://www.instagram.com/hack.alumni/"
            underline="none"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <Box display="flex" alignItems="center">
              <InstagramIcon
                style={{ fontSize: 25 }}
                className={classes.icon}
              />
              <Typography className={classes.instagramText}>
                Instagram
              </Typography>
            </Box>
          </Link>
        </Grid>
      </Grid>
    </footer>
  );
}
