import { Box, Grid } from "@material-ui/core";

import ReachoutForm from "./ReachOutForm";
import React from "react";
import TwoPeopleChatting from "../../assets/icons/two-people-chatting.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chattingIcon: {
    margin: `0 auto`,
    width: "100%",
    height: "100%",
    maxWidth: 550,
    maxHeight: 550,
    [theme.breakpoints.down("md")]: {
      maxWidth: 500,
      maxHeight: 500,
    },
  },
}));

export default function Reachout() {
  const classes = useStyles();
  return (
    <section>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} md={6}>
          <Box
            my={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img
              className={classes.chattingIcon}
              src={TwoPeopleChatting}
              alt="Icon of two people chatting"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ReachoutForm />
        </Grid>
      </Grid>
    </section>
  );
}
