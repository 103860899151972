import { Button, Typography } from "@material-ui/core";
import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    textAlign: "left",
  },
  dot: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryGreen.main,
    fontSize: "3rem",
    fontWeight: 700,
  },
  header: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "1.2rem",
    marginBottom: theme.spacing(3),
  },
  button: {
    borderRadius: theme.typography.pxToRem(24),
    width: 180,
    padding: theme.spacing(1.5, 2.25),
  },
  reachout: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[50]),
    boxShadow: "none",
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[100],
      boxShadow: "none",
    },
  },
}))(Button);

export default function CallOut() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Typography variant="h3" component="h1" className={classes.header}>
        The future of tech lives here<span className={classes.dot}>.</span>
      </Typography>
      <Typography className={classes.body} paragraph>
        Gain access to a community of over 150+ Black and Latinx technology
        professionals.
      </Typography>
      <ColorButton
        className={classes.button}
        color="primary"
        onClick={null}
        variant="contained"
        type="button"
      >
        <Typography className={classes.reachout} variant="h3">
          Reach Out
        </Typography>
      </ColorButton>
    </section>
  );
}
