import { Grid, Typography } from "@material-ui/core";
import CheckMarkIcon from "../../assets/images/checkmark.svg";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const WAYS_TO_SUPPORT = {
  row1: ["Participate in events", "Contribute to alumni growth"],
  row2: ["Send us ideas of your own"],
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    textAlign: "left",
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "1.2rem",
    marginBottom: theme.spacing(3),
  },
  catalystItem: {
    fontFamily: theme.typography.fontFamily,
    marginLeft: theme.spacing(2),
    fontSize: "1.2rem",
  },
  catalystPrompt: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryGreen.main,
    fontSize: "1.5rem",
    fontWeight: 900,
    marginBottom: theme.spacing(3),
  },
  checkMark: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(0.5),
  },
  itemMargin: {
    marginBottom: theme.spacing(2),
  },
  dot: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryGreen.main,
    fontSize: "3rem",
    fontWeight: 700,
  },
  header: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
}));

export default function SupportUs() {
  const classes = useStyles();

  return (
    <section className={classes.root} id="supportus">
      <Typography variant="h3" component="h1" className={classes.header}>
        Support Us<span className={classes.dot}>.</span>
      </Typography>
      <Typography className={classes.body} paragraph>
        Since 2017, Hack.Diversity has been committed to increasing diversity in
        the tech industry. To date, thanks to Hack.Diversity, over 150+
        Hack.Alumni will have contributed to 25+ companies accross 10+ sectors
        within Boston's innovation ecosystem.
      </Typography>
      <Typography className={classes.body} paragraph>
        Hack.Diversity's Alumni Leadership Council is building a community of
        alumni to help them grow and succeed beyond the Hack.Diversity
        Fellowship.
      </Typography>
      <Typography className={classes.catalystPrompt}>
        Curious about how you can be a catalyst for change?
      </Typography>
      <Grid container>
        {Object.keys(WAYS_TO_SUPPORT).map((row) => (
          <Grid item xs={12} lg={4} key={row}>
            {WAYS_TO_SUPPORT[row].map((item) => (
              <div key={item} className={classes.itemMargin}>
                <img
                  src={CheckMarkIcon}
                  className={classes.checkMark}
                  alt="Check Mark Icon"
                />
                <span className={classes.catalystItem}>{item}</span>
              </div>
            ))}
          </Grid>
        ))}
      </Grid>
    </section>
  );
}
