  import { Typography } from "@material-ui/core";
  import React from "react";
  import { makeStyles } from "@material-ui/core/styles";

  const useStyles = makeStyles((theme) => ({
    root: {
      fontFamily: theme.typography.fontFamily,
      padding: theme.spacing(3),
      textAlign: "left",
    },
    header: {
      color: theme.palette.primary.main
    },
    dot: {
      color: theme.palette.primaryGreen.main,
      fontSize: "3rem",
      fontWeight: 700,
    },
    body: {
      fontSize: "1.2rem",
      marginBottom: theme.spacing(3),
    }
  }));
  
  export default function AlcSection() {
    const classes = useStyles();
    
    return (
      <section className={classes.root} id="alcsection">
        <Typography variant="h3" component="h1" className={classes.header}>
          The Alumni Leadership Council<span className={classes.dot}>.</span>
        </Typography>
        <Typography className={classes.body} paragraph>
          The Alumni Leadership Council (ALC) is a branch of Hack.Diversity focused on building a diverse community of talendted professionals in the fields of Engineering, UI/UX Design, Data Analytics, and Information Technology.
        </Typography>
        <Typography className={classes.body} paragraph>
          The ALC works with core Hack.Diversity leadership to advance and support the development of all Hack.Alumni, to continue their path as role models building the future of tech.
        </Typography>
      </section>
    );
  }
