import {
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import { ErrorCodes, ErrorMessages } from "../../utils";
import { PRIMARY_JAF_DOMUS, SECONDARY_LATO } from "../../constants/fonts";
import React, { useState } from "react";
import { GRAY_50 } from "../../constants/colors";
import { makeStyles } from "@material-ui/core/styles";
import { submitReachoutForm } from "../../utils/airtableRequests.js";
import { isValidEmail } from "../../utils/validationHelpers";

const MAX_CHARACTERS_IN_MESSAGE = 100;
const MIN_CHARACTERS_IN_MESSAGE = 10;
const MAX_MESSAGE_TEXT_FIELD_ROWS = 2;
const MESSAGE = "Message";

const DEFAULT_REACHOUT_FORM_DATA = {
  Name: "",
  Company_Name: "",
  Email: "",
  [MESSAGE]: "",
  Status: "Unread",
};

const DEFAULT_ERRORS = {
  [MESSAGE]: undefined,
  requestError: undefined,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4),
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 14,
    maxWidth: 489,
    minHeight: 526,
    boxShadow: `0px 3px 10px 0px ${GRAY_50}`,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "88%",
    },
  },
  formTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontFamily: PRIMARY_JAF_DOMUS,
    fontSize: theme.typography.pxToRem(36),
  },
  form: {
    maxWidth: "90%",
  },
  submit: {
    marginTop: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: "Lato",
    boxShadow: "none",
    textAlign: "left",
    borderRadius: 28,
  },
  messageFormHelperText: {
    textAlign: "right",
  },
  successMessage: {
    marginTop: theme.spacing(1),
    color: theme.palette.success.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const useFormInputStyles = makeStyles((theme) => {
  const borderBottom = `1px solid ${theme.palette.primary.main}`;
  return {
    root: {
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: "-0.38px",
      fontWeight: 400,
      fontFamily: SECONDARY_LATO,
    },
    underline: {
      "&:hover:not($disabled):not($focused):before": {
        borderBottom,
      },
      "&:hover:not($disabled):before": {
        borderBottom,
      },
      "&:after": {
        borderBottom,
      },
    },
    focused: {
      color: theme.palette.primary.main,
    },
    disabled: {},
    error: {},
  };
});

function CustomFormInput(props) {
  const classes = useFormInputStyles();
  return (
    <TextField
      margin="normal"
      InputProps={{ classes }}
      fullWidth
      // removing required for now until we have a custom design for required fields
      // required
      {...props}
    />
  );
}

export default function ReachOutForm() {
  const [formData, setFormData] = useState(DEFAULT_REACHOUT_FORM_DATA);
  const [errors, setErrors] = useState(DEFAULT_ERRORS);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  const classes = useStyles();

  function updateErrors(
    fieldName,
    clearError = false,
    errorCode = ErrorCodes.UNKNOWN_ERROR,
  ) {
    setErrors({
      ...errors,
      [fieldName]:
        clearError === true
          ? undefined
          : ErrorMessages.ERROR_MESSAGES[errorCode],
    });
  }

  function handleChange(event) {
    if (successMessage) {
      setSuccessMessage(undefined);
    }

    const { name, value } = event.target;

    if (name === MESSAGE) {
      if (value.trim().length <= MAX_CHARACTERS_IN_MESSAGE) {
        setFormData({ ...formData, [name]: value });
        updateErrors(name, true /* clearError */);
      } else {
        updateErrors(
          name,
          false /* clearError */,
          ErrorCodes.REACHOUT_FORM_MESSAGE_TOO_LONG,
        );
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  }

  function validateFormData(form_data) {
    if (!form_data.Name.trim()) {
      throw ErrorCodes.REACHOUT_FORM_NAME_EMPTY;
    } else if (!isValidEmail(form_data.Email)) {
      throw ErrorCodes.INVALID_EMAIL;
    } else if (form_data.Message.length < MIN_CHARACTERS_IN_MESSAGE) {
      throw ErrorCodes.REACHOUT_FORM_MESSAGE_TOO_SHORT;
    }
  }

  async function submitForm(event) {
    event.preventDefault();

    try {
      validateFormData(formData);
    } catch (err) {
      updateErrors("requestError", false /* clearError */, err);
      return;
    }

    const payload = {
      records: [
        {
          fields: formData,
        },
      ],
    };

    try {
      setLoading(true);
      await submitReachoutForm(payload);
      setFormData(DEFAULT_REACHOUT_FORM_DATA);
      updateErrors("requestError", true /* clearError */);
      setLoading(false);
      setSuccessMessage(`Thank you! We'll get back to you soon 😊`);
    } catch {
      setLoading(false);
      setSuccessMessage(undefined);
      updateErrors(
        "requestError",
        false /* clearError */,
        ErrorCodes.GENERIC_REQUEST_ERROR,
      );
    }
  }

  const { message: messageError, requestError } = errors;
  const { Message: message, Email, Company_Name, Name } = formData;

  return (
    <Container maxWidth="xs" disableGutters>
      <div className={classes.paper}>
        <Typography
          className={classes.formTitle}
          component="h1"
          variant="h5"
          align="center"
        >
          Reach Out
        </Typography>
        <form className={classes.form} onSubmit={submitForm}>
          <CustomFormInput
            id="reachout-fullName"
            name="Name"
            label="Full Name"
            value={Name}
            required
            onChange={handleChange}
          />
          <CustomFormInput
            id="reachout-email"
            label="Email"
            name="Email"
            type="email"
            autoComplete="email"
            value={Email}
            required
            onChange={handleChange}
          />
          <CustomFormInput
            id="reachout-company"
            label="Company Name"
            name="Company_Name"
            value={Company_Name}
            onChange={handleChange}
          />
          <CustomFormInput
            id="reachout-message"
            label="Message"
            name="Message"
            value={message}
            onChange={handleChange}
            required
            helperText={
              messageError ?? `${message.length}/${MAX_CHARACTERS_IN_MESSAGE}`
            }
            FormHelperTextProps={{
              classes: {
                root: classes.messageFormHelperText,
              },
            }}
            rowsMax={MAX_MESSAGE_TEXT_FIELD_ROWS}
            error={messageError !== undefined}
            multiline
          />
          <Button
            className={classes.submit}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            {loading ? <CircularProgress color="#fff" size={30} /> : "Send"}
          </Button>
        </form>
        {requestError && (
          <Typography
            color="error"
            align="center"
            className={classes.errorMessage}
          >
            {requestError}
          </Typography>
        )}
        {successMessage && (
          <Typography className={classes.successMessage}>
            {successMessage}
          </Typography>
        )}
      </div>
    </Container>
  );
}
