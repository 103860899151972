/**
 * All error codes used throughtout the app. If there are missing error codes,
 * add them to the respective object.
 */

export const UNKNOWN_ERROR = "0000";
export const REACHOUT_FORM_NAME_EMPTY = "1000";
export const INVALID_EMAIL = "1001";
export const REACHOUT_FORM_MESSAGE_TOO_SHORT = "1002";
export const REACHOUT_FORM_MESSAGE_TOO_LONG = "1003";
export const GENERIC_REQUEST_ERROR = "1004";
