import { Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    textAlign: "left",
  },
  dot: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryGreen.main,
    fontSize: "3rem",
    fontWeight: 700,
  },
  header: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
}));

export default function OurObjectives() {
  const classes = useStyles();

  return (
    <section className={classes.root} id="alc-objectives">
      <Typography variant="h3" component="h1" className={classes.header}>
        Our Objectives<span className={classes.dot}>.</span>
      </Typography>
    </section>
  );
}
