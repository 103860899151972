import axios from "axios";

const AIRTABLE_API_BASE_URL = "https://api.airtable.com/v0";
const BASE_ID = `${process.env.GATSBY_AIRTABLE_BASE_ID}`;
const REACHOUT_TABLE = "Reach_Out_Form";
const SUBSCRIBE_TABLE = "Subscribe_Form";

const getAirtableEndpoint = (tableName) => {
  return `${AIRTABLE_API_BASE_URL}/${BASE_ID}/${tableName}`;
};

export const submitReachoutForm = async (payload) => {
  return axios.post(getAirtableEndpoint(REACHOUT_TABLE), payload, {
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`,
    },
  });
};

export const submitSubscribeForm = async (payload) => {
  return axios.post(getAirtableEndpoint(SUBSCRIBE_TABLE), payload, {
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`,
    },
  });
};
