import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    textAlign: "left",
  },
  header: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primaryGreen.main,
    fontSize: "3rem",
    fontWeight: 700,
  },
  highlightedText: {
    color: theme.palette.SECONDARY_LIGHT,
  },
}));

export default function WhoWeAre() {
  const classes = useStyles();

  return (
    <section className={classes.root} id="whoWeAre">
      <Typography variant="h3" component="h1" className={classes.header}>
        <span className={classes.header}>Who We Are</span>

        <span className={classes.body}>
          Hack.Alumni is a collective of highly skiled Black and Latinx
          individuals coming out of the{" "}
          <span className={classes.highlightedText}>Hack.Diversity</span>{" "}
          program.
        </span>

        <span className={classes.body}>
          Our mission is to encourage alumni to tap into this valuable
          community, stay connected, and support each individual to reach their
          maximum potential.
        </span>
      </Typography>
    </section>
  );
}
