import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorCodes, ErrorMessages } from "../../utils";
import { isValidEmail } from "../../utils/validationHelpers";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { submitSubscribeForm } from "../../utils/airtableRequests.js";

const DEFAULT_GETNOITIFIED_FORM_DATA = {
  Email: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  headerContainer: {
    [theme.breakpoints.up("xs")]: {
      maxWidth: theme.typography.pxToRem(200),
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.typography.pxToRem(225),
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.typography.pxToRem(250),
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: theme.typography.pxToRem(266),
    },
  },
  headerText: {
    color: theme.palette.primary.main,
    "& span": {
      color: theme.palette.primaryGreen.main,
    },
  },
  subHeaderText: {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  gridFormContainer: {
    marginTop: theme.typography.pxToRem(15),
  },
  textField: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.typography.pxToRem(26.496),
    color: theme.palette.primaryGreen.main,
    maxWidth: 388,
    "& .MuiInput-underline:before": {
      content: "none",
    },
    "& .MuiInput-underline:after": {
      content: "none",
    },
    "& label + .MuiInput-formControl": {
      margin: 0,
    },
    "& label": {
      width: "50%",
      paddingLeft: theme.typography.pxToRem(15),
    },
    "& .MuiInputBase-input": {
      padding: theme.typography.pxToRem(11.2),
    },
  },
  emailForm: {
    "& .MuiInputBase-input:-webkit-autofill": {
      borderRadius: theme.typography.pxToRem(24),
    },
  },
  button: {
    borderRadius: theme.typography.pxToRem(24),
    width: 180,
    padding: theme.spacing(1.5, 2.25),
    fontSize: theme.typography.pxToRem(16),
  },
  errorMessage: {
    fontWeight: theme.typography.fontWeightBold,
  },
  successMessage: {
    color: theme.palette.success.main,
    display: "flex",
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function EmailForm() {
  const [formData, setFormData] = useState(DEFAULT_GETNOITIFIED_FORM_DATA);
  const [errors, setErrors] = useState({
    requestError: undefined,
  });
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  function updateErrors(
    fieldName,
    clearError = false,
    errorCode = ErrorCodes.UNKNOWN_ERROR,
  ) {
    setErrors({
      ...errors,
      [fieldName]: clearError
        ? undefined
        : ErrorMessages.ERROR_MESSAGES[errorCode],
    });
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  async function submitForm(event) {
    event.preventDefault();
    setMessage("");

    if (!isValidEmail(formData.Email)) {
      updateErrors(
        "requestError",
        false /* clearError */,
        ErrorCodes.INVALID_EMAIL,
      );
      return;
    }

    const payload = {
      records: [
        {
          fields: formData,
        },
      ],
    };

    try {
      setLoading(true /* loading */);
      await submitSubscribeForm(payload);
      setFormData(DEFAULT_GETNOITIFIED_FORM_DATA);
      setMessage("Thank you for subscribing");
      updateErrors("requestError", true /* clearError */);
      setLoading(false /* loading */);
    } catch {
      setLoading(false /* loading */);
      setMessage(null);
      updateErrors(
        "requestError",
        false /* clearError */,
        ErrorCodes.INVALID_EMAIL,
      );
    }
  }

  const { requestError } = errors;

  const EmailFormButton = () => (
    <Button
      className={classes.button}
      color="primary"
      onClick={submitForm}
      variant="contained"
      type="submit"
    >
      {loading ? <CircularProgress color="primary" size={30} /> : "Subscribe"}
    </Button>
  );

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item className={classes.headerContainer}>
          <Typography variant="h2" className={classes.headerText}>
            Our new website is <span>on its way</span>.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.gridFormContainer}>
          <Typography className={classes.subHeaderText}>
            Get notified when we launch.
          </Typography>
          <form className={classes.emailForm} onSubmit={submitForm}>
            <TextField
              margin="none"
              autoComplete="off"
              className={classes.textField}
              fullWidth
              id="get-notified-form-input"
              InputProps={{ endAdornment: <EmailFormButton /> }}
              label="Email"
              name="Email"
              onChange={handleChange}
              type="email"
              value={formData.Email}
            />
          </form>
          {requestError && (
            <Typography color="error" className={classes.errorMessage}>
              {requestError}
            </Typography>
          )}
          {message && (
            <Typography className={classes.successMessage}>
              <CheckCircleOutlineIcon />
              {message}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
