import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  ListItem,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
  makeStyles,
  useScrollTrigger,
} from "@material-ui/core";
import { Close as CloseIcon, Menu as MenuIcon } from "@material-ui/icons";
import React, { useState } from "react";

import { Link as GatsbyLink } from "gatsby";
import HackAlumniLogo from "../../assets/logos/official-logo.svg";
import { PRIMARY_JAF_DOMUS } from "../../constants/fonts";
import WhiteHackAlumniLogo from "../../assets/logos/official-logoAllWhite.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  withMargin: {
    margin: theme.spacing(0, 1),
  },
  navigationBar: {
    backgroundColor: theme.palette.common.white,
    height: 80,
  },
  logoPlaceholder: {
    display: "inline-block",
    verticalAlign: "middle",
    flexGrow: 1,
    border: "8rem",
  },
  logoImageWrapper: {
    verticalAlign: "middle",
    maxHeight: 68,
    width: 150,
    [theme.breakpoints.up("sm")]: {
      width: 175,
    },
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  navigationButtons: {
    alignSelf: "right",
    marginRight: theme.spacing(4),
    borderRadius: 2,
    fontSize: theme.typography.pxToRem(16),
    fontFamily: PRIMARY_JAF_DOMUS,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: "none",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    "&:hover": {
      color: theme.palette.primaryGreen.main,
    },
  },
  hackDiversityButton: {
    alignSelf: "right",
    height: "2.5rem",
    width: "12rem",
    border: `0.2rem solid ${theme.palette.primaryGreen.main}`,
    fontFamily: PRIMARY_JAF_DOMUS,
    backgroundColor: theme.palette.primaryGreen.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    borderRadius: "2.8rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    "&:hover": {
      color: theme.palette.primaryGreen.main,
      backgroundColor: theme.palette.common.white,
      border: `0.125rem solid ${theme.palette.primaryGreen.main}`,
    },
  },
  drawerMenuIcon: {
    color: theme.palette.primaryGreen.main,
    marginRight: 0,
  },
  drawerMenu: {
    width: "100vw",
    height: "100vh",
    fontFamily: PRIMARY_JAF_DOMUS,
    backgroundColor: theme.palette.primaryGreen.main,
  },
  drawerCloseIcon: {
    color: "white",
    marginRight: 30,
  },
  drawerMenuItems: {
    color: theme.palette.common.white,
    fontFamily: PRIMARY_JAF_DOMUS,
    fontSize: "1.0rem",
  },
}));

const HACK_DIVERISITY_LINK = "https://hackdiversity.com/";

export const burgerMenuItems = [
  {
    name: "Home",
    to: "#home",
  },
  {
    name: "Support Us",
    to: "#supportus",
  },
  {
    name: "Visit hack.diversiy",
    to: HACK_DIVERISITY_LINK,
  },
];

export default function SitePlaceHolderHeader(props) {
  const { window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  const [state, setState] = useState({ right: false });

  const toggleDrawer = (anchor, open) => () => {
    setState({ ...state, [anchor]: open });
  };

  const DrawerButtons = () => (
    <div className={classes.drawerMenu} role="presentation">
      <Box display="flex" flexGrow={1}>
        <GatsbyLink to="/" className={classes.logoPlaceholder}>
          <img
            className={classes.logoImageWrapper}
            src={WhiteHackAlumniLogo}
            alt="Hack Alumni Logo"
          />
        </GatsbyLink>
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="close drawer"
            onClick={toggleDrawer("right", false)}
            className={classes.drawerCloseIcon}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      {burgerMenuItems.map((menuItem) => (
        <ListItem
          key={menuItem.name}
          button
          component={GatsbyLink}
          onClick={toggleDrawer("right", false)}
          to={menuItem.to}
        >
          <ListItemText
            classes={{ primary: classes.drawerMenuItems }}
            primary={menuItem.name}
          />
        </ListItem>
      ))}
    </div>
  );

  return (
    <div className={classes.root} id="home">
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          position="static"
          elevation={0}
          className={classes.navigationBar}
        >
          <Toolbar variant="dense">
            <div className={classes.logoPlaceholder}>
              <GatsbyLink to="/">
                <img
                  className={classes.logoImageWrapper}
                  src={HackAlumniLogo}
                  alt="Hack Alumni Logo"
                />
              </GatsbyLink>
            </div>
            <div>
              <Hidden xsDown>
                <Box flexDirection="row" justifyContent="flex-end">
                  <Typography
                    component={GatsbyLink}
                    to="#home"
                    className={classes.navigationButtons}
                  >
                    Home
                  </Typography>
                  <Typography
                    component={GatsbyLink}
                    to="#supportus"
                    className={classes.navigationButtons}
                  >
                    Support Us
                  </Typography>
                  <Button
                    href={HACK_DIVERISITY_LINK}
                    target="_blank"
                    className={classes.hackDiversityButton}
                    rel="noopener noreferrer"
                  >
                    Visit hack.diversity
                  </Button>
                </Box>
              </Hidden>

              <Hidden smUp>
                <React.Fragment key={"right"}>
                  <IconButton
                    aria-label="open drawer"
                    onClick={toggleDrawer("right", true)}
                    className={classes.drawerMenuIcon}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    anchor={"right"}
                    open={state["right"]}
                    onClose={toggleDrawer("right", false)}
                  >
                    {DrawerButtons("right")}
                  </Drawer>
                </React.Fragment>
              </Hidden>
            </div>
          </Toolbar>
        </AppBar>
      </Slide>
    </div>
  );
}
