import {
  Footer,
  Reachout,
  SitePlaceHolderHeader,
  SupportUs,
  FOOTER_HEIGHT,
  FOOTER_HEIGHT_DOWN_SM,
} from "../components";
import { MAX_SCREEN_WIDTH } from "../constants/site";
import Animation from "../assets/images/under-construction-illustration.png";
import { EmailForm } from "../components/shared";
import GreyFill from "../assets/icons/grey-fill.svg";
import GreyFillMobile from "../assets/icons/grey-fill-mobile.svg";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    minHeight: "100%",
  },
  getNofified: {
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(8, 0),
    },
  },
  animation: {
    maxWidth: "90%",
    maxHeight: "90%",
    [theme.breakpoints.up("md")]: {
      minWidth: 300,
      minHeight: 300,
    },
  },
  greyFill: {
    height: "auto",
    minHeight: 650,
    backgroundImage: `url(${GreyFill})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "initial",
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.only("xs")]: {
      minHeight: undefined,
      width: "100%",
      backgroundPosition: "right 100%",
      backgroundImage: `url(${GreyFillMobile})`,
      backgroundSize: "cover",
    },
  },
  reachoutFormWrapper: {
    position: "sticky",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(8),
    },
  },
  keepFooterBottom: {
    paddingBottom: FOOTER_HEIGHT,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: FOOTER_HEIGHT_DOWN_SM,
    },
  },
  center: {
    margin: "0 auto",
  },
  mainContainer: {
    maxWidth: MAX_SCREEN_WIDTH,
    width: "90%",
  },
}));

export default function IndexPage() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="home">
      <SitePlaceHolderHeader />
      <div className={classes.keepFooterBottom}>
        <div className={`${classes.mainContainer} ${classes.center}`}>
          <Grid container spacing={3} className={classes.getNofified}>
            <Grid item xs={12} md={5}>
              <EmailForm />
            </Grid>
            <Grid item xs={12} md={7}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <img
                  src={Animation}
                  className={classes.animation}
                  alt="Animation"
                />
              </Box>
            </Grid>
          </Grid>
          <SupportUs />
        </div>
        <div className={classes.greyFill} alt="grey svg wave background">
          <div
            className={`${classes.reachoutFormWrapper} ${classes.mainContainer}`}
          >
            <Reachout />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
