/**
 * All error messages used throughout the app. Each error message should have an error code.
 */

import * as ErrorCodes from "./errorCodes";

const infoIcon = String.fromCharCode("9432");

export const ERROR_MESSAGES = {
  [ErrorCodes.REACHOUT_FORM_NAME_EMPTY]: "Hey stranger! What's your name? 🤔",
  [ErrorCodes.REACHOUT_FORM_MESSAGE_TOO_SHORT]: `${infoIcon} Message field is empty or too short`,
  [ErrorCodes.REACHOUT_FORM_MESSAGE_TOO_LONG]: "This message is too long",
  [ErrorCodes.UNKNOWN_ERROR]: "Unknown error",
  [ErrorCodes.GENERIC_REQUEST_ERROR]:
    "Oops! Something went wrong, please try again 🙃",
  [ErrorCodes.INVALID_EMAIL]: `${infoIcon} Please enter a valid email address`,
};
